<template>
  <b-card class="shop-profile">
    <b-tabs
      pills
      class="user-profile-info"
      v-model="activeTab"
    >
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/account.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>
        <shop-profile
          ref="shopProfile"
          @profile-details="profileDetails"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/WeChat.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Social') }}</span>
        </template>
        <shop-social
          :store-details="storeDetails"
          @save-details="saveSocialDetails"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/reset_password.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Change Password') }}</span>
        </template>
        <change-password />
      </b-tab>
      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/Payment_methods.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Bank Details') }}</span>
        </template>
        <bank-details />
      </b-tab>
      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/subscription.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Subscription') }}</span>
        </template>
        <SubscriptionTab 
          :packageFor="`STORE`"
          class="mt-3"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab, BImg } from 'bootstrap-vue'
import ShopProfile from './shared/ShopProfile.vue'
import ChangePassword from './ChangePassword.vue'
import BankDetails from './BankDetails.vue'
import ShopSocial from './shared/ShopSocial.vue'
import SubscriptionTab from '@/views/shared/subscription/SubscriptionTab.vue'
import { mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BImg,
    ShopProfile,
    ChangePassword,
    BankDetails,
    ShopSocial,
    SubscriptionTab
  },
  data() {
    return {
      storeDetails: {},
      activeTab: 0
    }
  },
  methods: {
    ...mapActions('subscription', [
      'updateStoreSubscription'
    ]),
    profileDetails(payload) {
      this.storeDetails = payload
    },
    saveSocialDetails() {
      if (this.$refs.shopProfile) {
        this.$refs.shopProfile.saveStoreData()
      }
    },
  },
  mounted() {
    const store = window.SuperApp.getters.userInfo()
    let uk = 'store'+store.id
    let checkout_id = localStorage.getItem(uk);
    const queryParams = this.$route.query
    if(queryParams.success && checkout_id){
      localStorage.removeItem(uk)
      showLoader()
      this.updateStoreSubscription(checkout_id).then(r => {
          showSuccessNotification(this, 'Subscription updated successfully')
          this.activeTab = 4
          hideLoader()
      }).catch(e => {
          showErrorNotification(this, e)
          hideLoader()

      })
    }else if(queryParams.error){
      showErrorNotification(this, 'Something went wrong while updating your subscription')
    }
    if(!isEmpty(isEmpty)){
      this.$router.replace({'query': null});
    }
  }
}
</script>

<style lang="scss">
.user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.profile-img-height{
	width: 32px !important;
	height: 32px !important;
}
@media only screen and (max-width: 500px) {
  .profile-img-height{
    width: 16px !important;
    height: 16px !important;
  }
}
</style>
